.ticket-info-page {
  background-color: #000;
  color: #fff;
  font-family: 'Arial', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 40px; /* 하단 여백 추가 */
  box-sizing: border-box;
  overflow-y: auto; /* 내용이 많을 경우 스크롤 가능하도록 설정 */
  position: relative;
}

.popup-back-symbol-info {
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  position: absolute;
  top: 55px;
  left: 15px; /* 좌측으로 완전히 밀어 붙임 */
}

/* 전체 콘텐츠를 감싸는 래퍼 - 상단 여백 추가 */
.content-wrapper {
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 입장권 설명 박스 */
.ticket-description {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 90%;
  margin-bottom: 20px;
}

.ticket-title {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.ticket-detail {
  font-size: 1rem;
  margin-top: 5px;
}

/* 불렛 포인트 스타일 */
.bullet-points {
  width: 90%;
  text-align: left;
  margin-bottom: 20px;
}

.bullet-points ul {
  list-style-type: disc;
  padding-left: 20px;
  font-size: 1rem;
  line-height: 1.5;
}

/* 입장권 구매 버튼 */
.purchase-button {
  background-color: #c3f96e;
  color: black;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  width: 90%;
  text-align: center;
}
