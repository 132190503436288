/* adminAnnouncementPage.css */

/* Container for the Admin Announcement Page */
.admin-announcement-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
  }
  
  /* Page heading */
  .admin-announcement-title {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid #ccc;
    padding-bottom: 0.5rem;
  }
  
  /* Form styling */
  .admin-announcement-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-label {
    color: black;
    margin-bottom: 6px;
    font-weight: 500;
  }
  
  .form-input,
  .form-textarea {
    width: 100%;
    margin-bottom: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box;
  }
  
  /* Checkbox label styling */
  .checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-weight: 500;
  }
  
  /* Checkbox input */
  .form-checkbox {
    margin-right: 8px;
    transform: scale(1.2);
  }
  
  /* Submit button styling */
  .form-submit-button {
    padding: 10px 16px;
    font-weight: bold;
    font-size: 1rem;
    background-color: var(--real-point-color);
    color: black;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    transition: background-color 0.2s ease;
  }
  

  
  /* Status message styling */
  .status-message {
    margin-top: 16px;
    font-weight: bold;
  }
  