@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;700;900&display=swap');

:root {
  --main-background-color: black  ; /* Changed variable to avoid conflict */
  --main-text-color: #ffffff; /* Changed variable to avoid conflict */
  --main-button-color: var(--point-color);
  --main-input-background: #2a2a2a;
  --main-button-radius: 25px;
  --playlist-box-background: #2b2b2b;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background: var(--main-background-color);
  color: var(--main-text-color);
  font-family: 'Inter', sans-serif;
  padding-top: 20px;
  overflow: hidden;
  box-sizing: border-box;
}

.main-logo-container {
  position: absolute; /* Positioned to the top-left corner */
  top: 70px;
  left: 20px;
}
.main-logo {
  margin-top: 10%;
  width: 75px;  /* Adjust as needed */
  height: auto;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main-logo-text {
  color: #b3b3b3; /* Changed color to match the simpler look in screenshot */
  font-family: 'Orbitron', sans-serif; /* Keep the Orbitron font */
  font-size: 28px; /* Slightly reduced size */
  font-weight: 500; /* Reduced weight for a simpler style */
  letter-spacing: 1px; /* Adjusted letter spacing for a cleaner look */
  text-transform: uppercase;
  text-shadow: none; /* Removed the glowing text shadow */
}

.main-content {
  text-align: center;
  margin-top: 120px; /* Adjusted to make space for the moved logo */
}

.main-title {
  font-size: 28px;
  font-weight: 700;
  color: var(--main-text-color);
}

.main-subtitle {
  margin-top: 10px;
  font-size: 16px;
  color: var(--main-text-color);
  opacity: 0.8;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.playlist-input {
  width: 100%;
  max-width: 400px;
  padding: 14px;
  border: none;
  border-radius: var(--main-button-radius);
  background-color: var(--main-input-background);
  color: var(--main-text-color);
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
}

.play-button {
  padding: 15px 30px;
  border: none;
  background-color: var(--main-button-color);
  border-radius: var(--main-button-radius);
  font-size: 18px;
  font-weight: 700;
  color: var(--main-background-color);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* New playlist section styling */
.playlist-section {
  margin-top: 30px;
  padding: 0 10px;
  width: 100%;
  max-width: 1200px; /* 최대 너비 설정 */
}

.playlist-section-title {
  text-align: center;
  font-size: 2vh;
  font-weight: 700;
  color: var(--main-text-color);
  margin-bottom: 10px;
}

.playlist-box-container {
  display: flex; /* Flexbox로 변경 */
  flex-direction: row; /* 가로 방향으로 아이템 배치 */
  overflow-x: auto; /* 가로 스크롤 가능하게 설정 */
  gap: 15px; /* 아이템 간 간격 유지 */
  padding: 10px 20px;
  
  /* 스크롤바 스타일링 (옵션) */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: var(--point-color) #2a2a2a; /* Firefox */
  scroll-behavior: smooth; /* 부드러운 스크롤링 추가 */
}

.playlist-box-container::-webkit-scrollbar {
  height: 8px;
}

.playlist-box-container::-webkit-scrollbar-track {
  background: var(--main-input-background);
  border-radius: 4px;
}

.playlist-box-container::-webkit-scrollbar-thumb {
  background-color: var(--main-button-color);
  border-radius: 4px;
}

.playlist-box {
  background-color: var(--playlist-box-background);
  padding: 15px;
  border-radius: 12px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  /* 고정 너비 설정 */
  min-width: 15vh;
  flex: 0 0 auto; /* 아이템이 줄어들지 않고 고정된 너비를 유지 */
}

.playlist-box:hover {
  transform: translateY(-6px); /* 호버 시 더 많이 올라가도록 수정 */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5); /* 그림자 강화 */
  background-color: #3a3a3a; /* 호버 시 배경색 변경 */
}

.playlist-thumbnail {
  width: 100%;
  height: 150px; /* 고정 높이 설정 */
  overflow: hidden;
  border-radius: 8px; /* 이미지의 모서리를 둥글게 */
  margin-bottom: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playlist-image {
  width: 10vh;
  height: 10vh;
  object-fit: cover; /* 이미지가 컨테이너를 완전히 채우도록 설정 */
  display: block;
}

.playlist-icon {
  font-size: 40px; /* FontAwesome icon size for Spotify/Youtube icons */
  color: inherit;
}

.spotify-icon {
  color: #1db954; /* Spotify green color */
}

.youtube-icon {
  color: #ff0000; /* YouTube red color */
}

.playlist-name {
  font-size: 16px;
  font-weight: 600;
  color: var(--main-text-color);
  margin-bottom: 5px;
}

.playlist-description {
  font-size: 14px;
  color: var(--main-text-color);
  opacity: 0.7;
}

.error-message {
  color: red;
  text-align: center;
}

/* 로더 스타일링 */
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--main-text-color);
}

.loader p {
  margin-top: 10px;
  font-size: 16px;
  opacity: 0.8;
}

/* 반응형 디자인 강화 */
@media (max-width: 768px) {
  .playlist-box-container {
    gap: 15px; /* 간격 유지 */
  }

  .playlist-box {
    min-width: 15vh; /* 화면 크기에 따라 최소 너비 조정 */
  }

  .playlist-thumbnail {
    height: 120px; /* Reduced height for smaller screens */
  }
}

@media (max-width: 480px) {
  .main-logo-text {
    font-size: 24px; /* Further reduce for small screens */
  }

  .main-title {
    font-size: 24px;
  }

  .playlist-box-container {
    gap: 10px; /* 간격 조정 */
  }


  .playlist-thumbnail {
    height: 100px; /* Further reduced height */
  }

  .playlist-icon {
    font-size: 30px; /* Reduced icon size for small screens */
  }

  .playlist-name {
    font-size: 14px;
  }

  .playlist-description {
    font-size: 12px;
  }
}
.button-logo-spin {
  width: 32px;  /* Adjust size as needed */
  height: 32px;
  animation: spin 1s linear infinite;
  vertical-align: middle;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
