.hidden {
  display: none !important;
}
.ticket-purchase-page .main-content {
  margin-top: 35px;
  margin-bottom: 70px;
}
/* ✅ 결제 UI 화면 */
.payment-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.98);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* ✅ 결제 UI 컨테이너 (크기 조정) */
.payment-container {
  width: 90%;
  max-width: 600px; /* ✅ 최대 너비 제한 */
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* ✅ 결제 UI 위젯 크기 조정 */
#payment-method {
  width: 100%;
  max-width: 400px; /* ✅ 결제 방법 선택 UI 크기 */
  min-height: 300px;

  /* Add horizontal scroll if the payment icons overflow */
  overflow-x: auto;
  box-sizing: border-box;
}

/* Force the row of payment icons to wrap if they exceed the container width */
#payment-method [class^="payment-widget-cache-"],
#payment-method [class*=" payment-widget-cache-"] {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 8px !important;
  align-items: center !important;
  justify-content: center !important;
}

#agreement {
  width: 100%;
  max-width: 350px; /* ✅ 이용약관 UI 크기 */
  min-height: 100px;
}

.ticket-purchase-page {
  background-color: #f5f5f5; /* 배경 회색 */
  color: #000;
  font-family: 'Arial', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  overflow-y: auto; /* 스크롤 가능하도록 수정 */
}

/* 뒤로가기 버튼 */
.popup-back-symbol {
  font-size: 1.5rem;
  color: black;
  cursor: pointer;
  position: absolute;
  top: 77px;
  left: 15px;
}

/* 클럽 이름 */
.club-name {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #000;
}

/* 둥근 사각형 컨테이너 */
.section-container {
  width: 90%;
  background-color: #fff;
  padding: 15px;
  border-radius: 15px;
  margin: 10px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

@media (min-width: 1024px) {
  .section-container {
    max-width: 700px;
  }
}
@media (min-width: 768px) {
  .section-container {
    max-width: 600px; /* ✅ 컨텐츠도 더 넓게 */
  }
}

/* 정보 타이틀 */
.info-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #000;
}

/* 결제상세 섹션 */
.payment-title {
  font-size: 1.4rem;
  font-weight: bold;
  color: #000;
}

.payment-row {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  margin: 10px 0;
  color: #000;
}

.payment-row.total {
  font-weight: bold;
}

/* 체크박스 */
.agreement-section {
  text-align: left;
}

.agreement-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #000;
}

.agreement-text {
  font-size: 1rem;
  color: #555;
}

.checkbox-label {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin: 5px 0;
  color: #000;
}

.checkbox-label input {
  margin-right: 10px;
}

/* 구매 버튼 */
.purchase-button {
  width: 90%;
  padding: 15px;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  color: #fff;
  background-color: #B3B3B3; /* 비활성화 상태 (회색) */
}

.purchase-button.active {
  background-color: #c3f96e; /* 활성화 상태 (Spotify 초록) */
}

.purchase-button:disabled {
  cursor: not-allowed;
}

.day-selection label {
  display: flex;  /* ✅ 인라인 요소로만 되어있다면 flex 적용 */
  align-items: center; 
  gap: 8px;  /* ✅ 라디오 버튼과 텍스트 간격 추가 */
  font-size: 16px;  /* ✅ 가독성 개선 */
  color: black;  /* ✅ 색상이 배경과 겹치지 않도록 */
}

.day-selection label {
  display: flex;  /* ✅ 인라인 요소로만 되어있다면 flex 적용 */
  align-items: center; 
  gap: 8px;  /* ✅ 라디오 버튼과 텍스트 간격 추가 */
  font-size: 16px;  /* ✅ 가독성 개선 */
  color: black;  /* ✅ 색상이 배경과 겹치지 않도록 */
}

.day-selection label {
  display: block !important; /* ✅ 강제 표시 */
}

.quantity-button {
  padding: 5px 10px;
  font-weight: bold;
  border-radius: 15px;
  border: none;
  margin: 0 7px;
  color: #000;
}

.button-container {
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
}

/* Common styling to ensure same height */
.payment-request-button,
.cancel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px; /* Set a fixed height */
}

/* Payment button styles */
.payment-request-button {
  flex: 3;
  padding: 0 20px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(135deg, #1DB954, #1ED760);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.payment-request-button:hover {
  transform: scale(1.02);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.payment-request-button:active {
  transform: scale(0.98);
}

.payment-request-button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

/* Cancel button styles */
.cancel-button {
  flex: 2;
  padding: 0 20px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background: #ff4d4d;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.cancel-button:hover {
  transform: scale(1.02);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.cancel-button:active {
  transform: scale(0.98);
}

.cancel-button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
