/* PaymentSuccess.css */
.ticketsuccess-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    background: #000; /* 검은색 배경 */
    font-family: 'Roboto', sans-serif;
  }
  
  .ticketsuccess-box_section {
    background: rgba(0, 0, 0, 0.8);
    padding: 30px;
    border-radius: 12px;
    width: 100%;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
  }
  
  .ticketsuccess-box_section h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #fff;
  }
  
  /* 텍스트가 들어가는 회색 박스 */
  .ticketsuccess-text_box {
    background: #f2f2f2; /* 연한 회색 배경 */
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .ticketsuccess-text_box h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .ticketsuccess-separator {
    border: none;
    height: 1px;
    background-color: #ccc;
    margin: 10px 0 20px;
  }
  
  .ticketsuccess-text_box p {
    font-size: 18px;
    margin-bottom: 12px;
    color: #333;
  }
  
  /* Payment Key는 보이지 않도록 함 */
  .ticketsuccess-payment-key {
    display: none;
  }
  
  /* 트렌디한 버튼 스타일 (모바일 환경, hover 효과 제거) */
  .ticketsuccess-confirm_button {
    margin-top: 20px;
    padding: 12px 20px;
    background: linear-gradient(45deg, #ff6a00, #ee0979);
    border: none;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
  }
  