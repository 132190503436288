:root {
  --background-color: #0a0a0a;
  --text-color: #ffffff;
  --text-secondary: #b3b3b3;
  --accent-color: #1db954; /* 버튼 색상을 초록색으로 변경 */
  --button-hover-color: #c3f96e;
}

.find-id-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: var(--background-color);
  min-height: 100vh;
  padding: 20px;
  font-family: 'Inter', sans-serif;
  color: var(--text-color);
}

.find-id-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
}

.find-id-description {
  font-size: 14px;
  color: var(--text-secondary);
  text-align: center;
  margin-bottom: 20px;
}

.tab-container {
  display: flex;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.tab-btn {
  flex: 1;
  padding: 14px;
  border: 2px solid var(--text-secondary);
  border-radius: 8px;
  color: var(--text-secondary);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tab-btn.active {
  background-color: var(--text-color);
  color: var(--background-color);
}

.find-id-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

.input-field {
  width: 100%;
  padding: 14px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  background-color: #1f1f1f;
  color: #ffffff;
}

.find-id-submit-btn {
  width: 100%;
  padding: 16px;
  margin-top: 20px;
  background-color: var(--accent-color); /* 초록색으로 설정 */
  color: var(--background-color);
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.find-id-submit-btn:hover {
  background-color: var(--button-hover-color); /* 초록색 hover 효과 */
}

@media (max-width: 480px) {
  .find-id-title {
    font-size: 28px;
  }

  .input-field {
    font-size: 14px;
    padding: 12px;
  }

  .tab-btn {
    font-size: 14px;
  }

  .find-id-submit-btn {
    font-size: 16px;
    padding: 14px;
  }
}
