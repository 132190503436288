.my-page {
  background-color: #000;
  color: white;
  min-height: 100vh;
  padding-bottom: 60px; /* Space for Navbar */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-section {
  margin-top: 10vh;
  text-align: center;
}

.profile-icon {
  font-size: 80px;
  color: var(--point-color);
}

.profile-section h2 {
  margin-top: 10px;
  font-size: 24px;
}

.profile-section p {
  margin-top: 5px;
  font-size: 16px;
  color: #ccc;
}

.button-section {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.profile-button {
  background-color: var(--point-color);
  color: #000;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
}


.settings-section {
  margin-top: 40px;
  width: 80%;
}

.settings-section ul {
  list-style: none;
  padding: 0;
}

.settings-section li {
  font-size: 16px;
  padding: 15px 0;
  border-bottom: 1px solid #333;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.settings-section li:hover {
  color: var(--point-color);
}

.logout-button {
  background-color: var(--point-color);
  color: #000;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.logout-button .logout-icon {
  margin-right: 8px;
}


.mypage-v2-button {
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 15px;
}

