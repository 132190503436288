/* ClubCard.css */
.club-card-component {
  background-color: var(--primary-color); /* 트렌디한 검은색 */
  color: #ffffff; /* 흰색 텍스트 */
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* 어두운 그림자 */
  overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.club-card-component.selected {
  border: 2px solid var(--point-color);
  box-shadow: 0 4px 20px var(--point-color)
}

.club-card-image-container {
  width: 100%;
  height: 150px;
  overflow: hidden;
  background-color: #333333; /* 이미지 없을 때 어두운 배경 */
  position: relative; /* 하트 아이콘 위치 설정을 위해 필요 */
}

.club-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.club-card-like-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: var(--point-color); /* 하트 아이콘 기본 색상 */
  font-size: 20px;
  cursor: pointer;
}

.club-card-like-button:hover {
  transform: none; /* 모바일 앱이므로 hover 효과 제거 */
}

.club-card-content {
  padding: 15px;
}

.club-card-name {
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0;
}

.club-card-genre {
  font-size: 14px;
  color: #c3c3c3; /* 장르 텍스트는 밝은 회색 */
}

.club-card-ticket-button {
  display: none;
  width: 100%;
  padding: 10px 0;
  margin-top: 10px;
  background-color: #ffffff; /* 흰색 버튼 */
  color: #000000; /* 검은 텍스트 */
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.club-card-ticket-button:hover {
  background-color: #f0f0f0;
}

.club-card-like-count {
  font-size: 12px;
  color: #c3c3c3; /* 밝은 회색 텍스트 */
  margin-top: 5px;
  text-align: center; /* 텍스트 가운데 정렬 */
}

.match-progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.progress-circle {
  width: 50px;
  height: 50px;
}

.progress-bg {
  fill: none;
  stroke: #444444; /* 어두운 배경에 적합한 배경선 */
  stroke-width: 1.5;
}

.progress {
  fill: none;
  stroke: #00bfff; /* 파란색 진행선 */
  stroke-width: 1.5;
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: center;
  transition: stroke-dashoffset 1s ease-in-out;
}

.progress-text {
  fill: #ffffff; /* 흰색 텍스트 */
  font-size: 0.5em;
  text-anchor: middle;
  alignment-baseline: middle;
}

.match-label {
  margin-top: 5px;
  font-size: 12px;
  color: #c3c3c3; /* 라벨은 밝은 회색 */
}

.highlight {
  animation: highlight 2s ease;
}

@keyframes highlight {
  from {
    background-color: var(--point-color);
  }
  to {
    background-color: transparent;
  }
}

@media (max-width: 480px) {
  .club-card-component {
    width: calc(100% - 70px); /* 작은 화면에서도 적절한 간격 유지 */
    max-width: calc(100% - 20px);
    margin-bottom: 20px;
    top: 0vh;
  }

  .club-card-image-container {
    height: 140px;
  }
}
