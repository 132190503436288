.footer {
  background-color: #222;
  color: #fff;
  padding: 20px 10px;
  font-size: 0.9rem;
  margin-bottom: 17px;
}

.footer-content {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

/* 기존 company-info 스타일 */
.company-info {
  text-align: left;
  margin-bottom: 10px;
}

/* header 영역: 제목과 드롭다운 버튼을 같은 줄에 배치 */
.company-info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company-info-header h4 {
  margin-bottom: 8px;
  font-size: 1rem;
}

/* 드롭다운 버튼 스타일 */
.dropdown-button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 0.9rem;
  transition: color 0.3s;
}
.dropdown-button:hover {
  color: #bbb;
}

/* 드롭다운된 상세 정보 */
.company-details {
  margin-top: 10px;
}

.company-details p {
  margin: 3px 0;
}

.legal-links {
  margin-top: 10px;
}

.legal-links a {
  color: #bbb;
  text-decoration: none;
  margin: 0 5px;
  transition: color 0.3s;
}

.legal-links a:hover {
  color: #fff;
}

copyright {
  margin-top: 10px;
  color: #aaa;
}
