.mypagev2 {
  background-color: #000;
  color: #fff;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/* 배경 애니메이션: 대각선 패턴 */
.mypagev2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    rgba(255, 255, 255, 0.05) 10px,
    rgba(255, 255, 255, 0.05) 20px
  );
  animation: moveBackground 30s linear infinite;
  z-index: -1;
}
@keyframes moveBackground {
  from { transform: translate(0, 0); }
  to { transform: translate(-50%, -50%); }
}

/* 강조 텍스트: Spotify Green */
.highlight {
  color: #1db954;
  font-weight: bold;
}

/* 헤더: 오른쪽 상단 설정 아이콘 */
.mypagev2-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.mypagev2-settings-icon {
  margin-top: 18%;
  font-size: 28px;
  cursor: pointer;
  color: #fff;
  transition: color 0.3s;
}
.mypagev2-settings-icon:hover {
  color: #aaa;
}

/* 콘텐츠 카드: 어두운 회색 텍스트 박스 */
.mypagev2-content {
  background-color: #222;
  border: 1px solid #444;
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  max-width: 480px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
}

/* 이미지 영역 */
.mypagev2-image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.mypagev2-image {
  width: 100%;
  max-width: 300px;
  border-radius: 15px;
  object-fit: cover;
}

/* 텍스트 정보 */
.mypagev2-text-info {
  text-align: center;
}
.mypagev2-user-name {
  font-size: 22px;
  margin-bottom: 10px;
  font-weight: bold;
}
.mypagev2-user-text,
.mypagev2-genre-text,
.mypagev2-club-location,
.mypagev2-visit-count {
  font-size: 16px;
  margin: 8px 0;
}
.mypagev2-user-text strong,
.mypagev2-genre-text strong {
  font-weight: bold;
}
.mypagev2-club-location {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccc;
}
.mypagev2-location-icon {
  margin-right: 6px;
}
.mypagev2-visit-count span {
  font-size: 20px;
  font-weight: bold;
}

/* 추천 클럽 영역 */
.mypagev2-recommend-section {
  background-color: #222;
  border: 1px solid #444;
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  max-width: 480px;
  margin-top: 30px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
}
.mypagev2-recommend-title {
  font-size: 18px;
  margin-bottom: 15px;
  color: #fff;
}
.mypagev2-recommend-button {
  background-color: #fff;
  border: none;
  border-radius: 50px;
  color: #000;
  padding: 12px 30px;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}
.mypagev2-recommend-button:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(255, 255, 255, 0.2);
}

/* 방문 지역 그래프 영역 */
.mypagev2-graph-section {
  background-color: #222;
  border: 1px solid #444;
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  max-width: 480px;
  margin-top: 30px;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
}
.mypagev2-graph-title {
  font-size: 18px;
  margin-bottom: 15px;
  color: #fff;
  text-align: center;
}
.mypagev2-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.bar-label {
  width: 60px;
  font-size: 14px;
  text-align: right;
  margin-right: 10px;
  color: #fff;
}
.bar-container {
  flex-grow: 1;
  background-color: #444;
  border-radius: 10px;
  overflow: hidden;
}
.bar {
  height: 20px;
  border-radius: 10px;
  background-color: #fff;
  width: 0;
  animation: expandBar 1.5s ease-out forwards;
}
@keyframes expandBar {
  from { width: 0; }
  to { width: var(--bar-width); }
}
.bar-value {
  position: relative;
  margin-left: 5px;
  color: #000;
  font-size: 12px;
  line-height: 20px;
}

/* Fallback UI 스타일 (개선된 버전) */
.fallback {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 20px;
  min-height: 100vh;
  background-color: #000;
}

.fallback-content {
  text-align: center;
  margin-top: 30vh;
  margin-bottom: 10vh;
}


.fallback-button {
  background-color: white;
  border: none;
  border-radius: 50px;
  padding: 15px 30px;
  font-weight: bold;
  color: black;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.fallback-button:hover {
  transform: translateY(-2px);
}

/* 모바일 화면 최적화 */
@media (max-width: 480px) {
  .mypagev2 {
    overflow-x: hidden;
    padding: 10px;
  }
  .mypagev2-content,
  .mypagev2-recommend-section,
  .mypagev2-graph-section {
    margin-top: 20px;
    padding: 15px;
  }
  .mypagev2-user-name {
    font-size: 20px;
  }
  .mypagev2-user-text,
  .mypagev2-genre-text,
  .mypagev2-club-location,
  .mypagev2-visit-count {
    font-size: 14px;
  }
  .mypagev2-recommend-button {
    font-size: 14px;
    padding: 10px 25px;
  }
}

/* ================================= */
/* =  SIDE-DRAWER FOR OLD MyPage   = */
/* ================================= */
.mypagev2-side-drawer {
  position: fixed;
  top: 0;
  right: -100%; /* 화면 오른쪽 밖에 배치 (기본 숨김) */
  width: 100%;
  background-color: black;
  color: #fff;
  transition: right 0.3s ease-in-out;
  z-index: 9999;
  overflow-y: auto; /* 긴 목록 스크롤 */
}

.mypagev2-side-drawer.open {
  right: 0; /* 열릴 때 0으로 이동 */
}

/* MyPage 닫기 버튼( X ) 위치 조정 */
.my-page .close-button {
  position: absolute;
  top: 9vh;
  right: 16px;
  background: transparent;
  border: none;
  font-size: 1.2rem;
  color: #fff;
  cursor: pointer;
  transition: color 0.2s;
}
.my-page .close-button:hover {
  color: #aaa;
}
