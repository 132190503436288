:root {
    --background-color: #0a0a0a;
    --text-color: #ffffff;
    --text-secondary: #b3b3b3;
    --accent-color: #1db954; /* 초록색 */
    --button-hover-color: #c3f96e;
  }
  
  .reset-password-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 20px;
    font-family: 'Inter', sans-serif;
  }
  
  .reset-password-title {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  
  .reset-password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
  }
  
  .input-field {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    background-color: #222;
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .reset-password-btn {
    width: 40%;
    padding: 12px;
    background-color: var(--accent-color);
    color: var(--background-color);
    font-size: 15px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .reset-password-btn:hover {
    background-color: var(--button-hover-color);
  }
  
  .error-message {
    color: #ff4d4d;
    font-size: 14px;
    margin-top: 10px;
  }
  