:root {
    --background-color: #0a0a0a;
    --text-color: #ffffff;
    --text-secondary: #b3b3b3;
    --accent-color: #ff4081;
  }
  
  .signup-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: var(--background-color);
    min-height: 100vh;
    padding: 20px;
    font-family: 'Inter', sans-serif;
    color: var(--text-color);
    position: relative;
  }
  
  .signup-title {
    margin-top: 20%;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .signup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
  }
  
  .input-field {
    width: 100%;
    padding: 14px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    background-color: #1f1f1f;
    color: #ffffff;
  }
  
  .gender-selection {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .gender-label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .gender-buttons {
    display: flex;
    gap: 20px;
  }
  
  .gender-btn {
    flex: 1;
    padding: 14px;
    border: 2px solid var(--text-secondary);
    border-radius: 8px;
    color: var(--text-secondary);
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .gender-btn.active {
    background-color: var(--text-color);
    color: var(--background-color);
  }
  
  .agreements {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
    color: var(--text-secondary);
  }
  
  .agreements label {
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  .marketing-options {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .signup-submit-btn {
    width: 100%;
    padding: 16px;
    background-color: #444;
    color: var(--background-color);
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: not-allowed;
    transition: background-color 0.3s ease;
  }
  
  .signup-submit-btn.active {
    background-color: #1db954;
    cursor: pointer;
  }
  
  .signup-submit-btn:hover.active {
    background-color: #17a547;
  }
  
  @media (max-width: 480px) {
    .signup-title {
      font-size: 28px;
    }
  
    .input-field {
      font-size: 14px;
      padding: 12px;
    }
  
    .gender-btn {
      font-size: 14px;
    }
  
    .signup-submit-btn {
      font-size: 16px;
      padding: 14px;
    }
  }
  
  /* 인증번호 전송 버튼 스타일 */
  .signup-form .send-code-btn {
    background-color: #333333; /* 배경색 */
    color: white; /* 글자색 */
    border: none; /* 테두리 제거 */
    padding: 10px 20px; /* 패딩 */
    cursor: pointer; /* 커서 모양 */
    border-radius: 5px; /* 모서리 둥글게 */
    transition: background-color 0.3s; /* 배경색 변화 애니메이션 */
  }
  
  .signup-form .send-code-btn:hover {
    background-color: #444444; /* 호버 시 배경색 변화 */
  }
  
  /* placeholder 색상 */
  .input-field::placeholder {
    color: #666666; /* placeholder 색상 */
  }
  
  /* 인풋 필드와 버튼을 가로로 배치할 컨테이너 */
  .input-button-container {
    display: flex; /* Flexbox 사용 */
    align-items: stretch; /* 요소의 높이를 동일하게 유지 */
    gap: 10px; /* 요소 간의 간격 */
  }
  
  /* 인풋 필드 스타일 */
  .input-field {
    flex: 1; /* 가로로 가능한 공간을 모두 차지 */
    
    padding: 0 10px; /* 패딩 */
    border: 1px solid #ccc; /* 테두리 */
    border-radius: 5px; /* 모서리 둥글게 */
  }
  
  /* 인증번호 전송 버튼 스타일 */
  .send-code-btn {
    background-color: #333333; /* 배경색 */
    color: white; /* 글자색 */
    border: none; /* 테두리 제거 */
    height: 38.5px; /* 높이 유지 */
    padding: 0 20px; /* 패딩 */
    cursor: pointer; /* 커서 모양 */
    border-radius: 5px; /* 모서리 둥글게 */
    transition: background-color 0.3s; /* 배경색 변화 애니메이션 */
  }
  
  .send-code-btn:hover {
    background-color: #444444; /* 호버 시 배경색 변화 */
  }
  
  /* placeholder 색상 */
  .input-field::placeholder {
    color: #666666; /* placeholder 색상 */
  }
  
  /* 인증번호 입력 필드와 버튼을 가로로 배치할 컨테이너 */
  .verification-input-button-container {
    display: flex; /* Flexbox 사용 */
    align-items: stretch; /* 요소의 높이를 동일하게 유지 */
    gap: 10px; /* 요소 간의 간격 */
  }
  
  /* 인풋 필드 스타일 */
  .input-field {
    flex: 1; /* 가로로 가능한 공간을 모두 차지 */
    
    padding: 0 10px; /* 패딩 */
    border: 1px solid #ccc; /* 테두리 */
    border-radius: 5px; /* 모서리 둥글게 */
  }
  
  /* 인증번호 확인 버튼 스타일 */
  .verify-code-btn {
    background-color: #333333; /* 배경색 */
    color: white; /* 글자색 */
    border: none; /* 테두리 제거 */
    height: 38.5px; /* 높이 유지 */
    width: 139.6px;
    padding: 0 20px; /* 패딩 */
    cursor: pointer; /* 커서 모양 */
    border-radius: 5px; /* 모서리 둥글게 */
    transition: background-color 0.3s; /* 배경색 변화 애니메이션 */
  }
  
  .verify-code-btn:hover {
    background-color: #444444; /* 호버 시 배경색 변화 */
  }
  
  /* placeholder 색상 */
  .input-field::placeholder {
    color: #666666; /* placeholder 색상 */
  }
  
  a {
    color: #1db954;           /* 흰색 텍스트 */

  }
  

  