@media (max-width: 767px) {
    .notification-popup {
      position: fixed;
      top: calc(env(safe-area-inset-top, 0px) + 50px); /* 모바일 안전 영역 고려 */
      left: 50%;
      transform: translateX(-50%);
      background: #000;             /* 검정 배경 */
      color: #fff;
      padding: 16px 32px;
      border: none;
      border-radius: 10px;

      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
      z-index: 9999;               /* 충분히 높은 값 */
      width: 70%;
      max-width: 500px;            /* 너무 넓지 않도록 제한 */
      text-align: center;
      font-family: 'Inter', sans-serif;
      font-size: 1rem;
      opacity: 0;
      animation: fadeInOut 3s forwards;
    }
  }
  
  @media (min-width: 768px) {
    .notification-popup {
      display: none;
    }
  }
  
  @keyframes fadeInOut {
    0% { opacity: 0; }
    20%, 80% { opacity: 1; }
    100% { opacity: 0; }
  }
  