/* src/Components/Navbar/Navbar.css */

.navbar {
  position: fixed;
  bottom: 0px;
  top: 92%;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: space-evenly;
  padding: 20px 0px;
  border-top: 1px solid #333;
}

.navbar a {
  text-decoration: none;
  color: #777;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar a .icon {
  font-size: 20px;
  margin-bottom: 5px;
}

.navbar .active {
  color: var(--point-color); /* Green color for active tab */
}

.navbar .active .icon {
  color: var(--point-color);
}
