.landing-container {
  min-height: 100vh;
  background-color: #000;
  color: #fff;
  padding: 2rem;
  overflow-x: hidden;
}

.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 1rem;
}

.hero-content {
  flex: 1;
}

.hero-title {
  font-size: 4rem;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 1.5rem;
}

/* 그라디언트 텍스트 */
.gradient-text {
  background: linear-gradient(to right, #ff3366, #ff9933);

  /* 웹킷 계열 브라우저(Chrome, Safari 등)용 */
  -webkit-background-clip: text; 
  /* 표준 속성 */
  background-clip: text;

  /* 웹킷에서 텍스트를 투명 처리하여 배경 그라디언트가 보이게 함 */
  -webkit-text-fill-color: transparent;
  /* 그 외 브라우저 대비 (혹은 fallback) */
  color: transparent;
}

.hero-subtitle {
  font-size: 1.25rem;
  color: #999;
  margin-bottom: 2rem;
}

.hero-image {
  flex: 1;
  position: relative;
}

.hero-image img {
  width: 100%;
  height: auto;
  border-radius: 1rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.start-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #ff3366, #ff9933);
  color: white;
  padding: 1rem 2rem;
  border-radius: 0.75rem;
  font-weight: 600;
  font-size: 1.125rem;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
}

.start-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px -5px rgba(255, 51, 102, 0.3);
}

.features-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 6rem auto;
  padding: 0 1rem;
}

.feature-card {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-icon {
  width: 2.5rem;
  height: 2.5rem;
  color: #ff3366;
  margin-bottom: 1rem;
}

.feature-card h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.feature-card p {
  color: #999;
  line-height: 1.6;
}

.testimonials-section {
  max-width: 1200px;
  margin: 6rem auto;
  padding: 0 1rem;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3rem;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.testimonial-card {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 1rem;
  text-align: center;
  backdrop-filter: blur(10px);
}

.testimonial-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 auto 1rem;
  object-fit: cover;
}

.testimonial-card p {
  font-size: 1.125rem;
  color: #fff;
  margin-bottom: 1rem;
  font-style: italic;
}

.testimonial-name {
  color: #999;
  font-size: 0.875rem;
}

/* 테스트 진행 페이지 */
.test-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.test-box {
  max-width: 600px;
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.question-progress-container {
  margin-bottom: 2rem;
  text-align: center;
}

.question-counter {
  font-size: 1rem;
  font-weight: 600;
  color: #ff9933;
  margin-bottom: 0.5rem;
}

.progress-bar-bg {
  width: 100%;
  height: 8px;
  background-color: #333;
  border-radius: 4px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background: linear-gradient(to right, #ff3366, #ff9933);
  transition: width 0.3s ease;
}

.question-text {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-align: center;
}

.options-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.option-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #111;
  border: none;
  padding: 1rem;
  border-radius: 0.75rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: #fff;
}

.option-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px -5px rgba(255, 51, 102, 0.3);
}

.option-button .arrow-icon {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.option-button:hover .arrow-icon {
  opacity: 1;
}

/* 결과 페이지 */
.result-page {
  display: flex;
  align-items: center;
  justify-content: center;
}

.result-box {
  max-width: 600px;
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  position: relative;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

/* 네온/그라디언트 테두리 효과 */
.result-box::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 1rem;
  padding: 2px;
  background: linear-gradient(135deg, #ff3366, #ff9933);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask: 
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
  pointer-events: none;
}

.result-header {
  text-align: center;
  margin-bottom: 1.5rem;
}

.result-icon {
  width: 3rem;
  height: 3rem;
  color: #ff3366;
}

.result-heading {
  font-size: 1.75rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.5rem;
  color: #fff;
}

.result-content {
  text-align: center;
}

.result-title {
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 1rem;
  background: linear-gradient(to right, #ff3366, #ff9933);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.result-description {
  font-size: 1.125rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  color: #f1f1f1;
}

.result-recommendation {
  font-size: 1rem;
  font-weight: 700;
  /* 기존 result-title과 동일한 그라데이션 스타일 */
  background: linear-gradient(to right, #ff3366, #ff9933);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  margin-bottom: 1rem;
}

/* 버튼 (공유, 다시하기) */
.share-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  background: linear-gradient(to right, #ff3366, #ff9933);
  color: #fff;
  padding: 1rem;
  border-radius: 0.75rem;
  font-weight: 600;
  font-size: 1.125rem;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  margin: 0.5rem;
  text-decoration: none; /* 밑줄 제거 */
}

/*.share-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px -5px rgba(255, 51, 102, 0.3);
}*/

/* 푸터 영역 */
.landing-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
  padding: 1rem 0;
  border-top: 1px solid #444;
}

.footer-button {
  width: 30%;
  background-color: #ff3366;
  color: #fff;
  border: none;
  padding: 0.8rem 1.2rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
}

/* 모달 공통 스타일 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background-color: #111;
  color: #fff;
  padding: 2rem;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
  text-align: left;
}

.close-modal-btn {
  display: inline-block;
  background-color: #444;
  color: #fff;
  border: none;
  padding: 0.6rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
}

/* 문의하기 폼 */
.inquiry-form label {
  font-weight: bold;
  display: block;
  margin: 0.5rem 0 0.25rem;
}

.inquiry-form select,
.inquiry-form input,
.inquiry-form textarea {
  padding: 0.8rem;
  border-radius: 4px;
  border: 1px solid #666;
  background-color: #222;
  color: #fff;
  margin-bottom: 1rem;
}

.inquiry-agreement {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.inquiry-agreement input[type="checkbox"] {
  margin-right: 0.5rem;
}

.inquiry-status {
  margin-top: 1rem;
  color: #ff9933;
  font-weight: bold;
}

/* 알림 팝업 관련 */
.notification-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.notification-popup-content {
  background: #222;
  color: #fff;
  padding: 2rem;
  border-radius: 8px;
  max-width: 300px;
  width: 80%;
  text-align: center;
}

.notification-popup-content p {
  margin-bottom: 1rem;
}

.notification-popup-content button {
  background: #ff3366;
  color: #fff;
  border: none;
  padding: 0.8rem 1.2rem;
  border-radius: 4px;
  cursor: pointer;
}
  
/* 반응형 디자인 */
@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
    gap: 2rem;
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .features-section {
    grid-template-columns: 1fr;
  }

  .testimonials-grid {
    grid-template-columns: 1fr;
  }
}

.footer-link-group {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}