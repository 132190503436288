@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;700;900&display=swap');

:root {
  --background-color: #0a0a0a;
  --text-color: #ffffff;
  --text-secondary: #b3b3b3;
  --accent-color: #ff4081;
}
form {
  display: flex; /* Flexbox 사용 */
  flex-direction: column; /* 세로 정렬 */
  align-items: center; /* 가로 중앙 정렬 */
  justify-content: center; /* 세로 중앙 정렬 */
  width: 90%;
  padding: 20px; /* 내부 여백 */
  border-radius: 8px; /* 모서리 둥글게 */
}
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #0a0a0a, #1f1f1f);
  min-height: 100vh;
  padding: 20px;
  font-family: 'Inter', sans-serif;
  color: var(--text-color);
  position: relative;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  animation: fadeIn 1s ease-in-out;
}

.logo-text {
  color: var(--text-color);
  font-family: 'Orbitron', sans-serif;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 5px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.logo-subtitle {
  color: var(--text-secondary);
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.title-container {
  text-align: center;
  margin-bottom: 20px; 
}

.login-subtitle {
  color: var(--text-secondary);
  font-size: 16px;
  font-weight: 400;
}

.input-field {
  width: 100%;
  max-width: 320px;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  background-color: #1f1f1f;
  color: #ffffff;
}

.login-submit-btn {
  width: 100%;
  max-width: 320px;
  padding: 14px;
  margin-top: 10px;
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-submit-btn:hover {
  background-color: #333;
}

.find-info {
  margin-top: 10px;
  margin-bottom: 20px; 
  color: var(--text-secondary);
  font-size: 14px;
  text-align: center;
}

.login-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 320px;
  gap: 20px;
  animation: slideInUp 1s ease-in-out;
}

.login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.login-btn .icon {
  margin-right: 10px;
  font-size: 22px;
}

.login-btn:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5);
}

.login-btn.google{
  background-color:#000000;
  color: #ffffff;
}

.login-btn.kakao {
  background-color: #fee500;
  color: #000000;
}

.login-btn.spotify {
  background-color: #1db954;
  color: #ffffff;
}

.login-btn.apple {
  background-color: #ffffff;
  color: #000000;
}

.signup-btn {
  width: 100%;
  max-width: 320px;
  padding: 14px;
  margin-top: 20px;
  background-color: transparent;
  color: var(--text-color);
  font-size: 16px;
  font-weight: bold;
  border: 2px solid var(--text-color);
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.signup-btn:hover {
  background-color: var(--text-color);
  color: var(--background-color);
}


.later-link {
  margin-top: 20px; 
  color: var(--text-secondary);
  font-size: 14px;
  cursor: pointer;
  transition: color 0.3s ease;
  text-decoration: underline;
}

.later-link:hover {
  color: var(--accent-color);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 480px) {
  .logo-text {
    font-size: 38px;
  }

  .logo-subtitle {
    font-size: 14px;
  }

  .login-btn {
    font-size: 14px;
    padding: 12px;
  }

  .login-buttons {
    gap: 15px;
  }
}
.alert-message {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ffcc00;
  background-color: #fff3cd;
  color: #856404;
  border-radius: 5px;
  font-size: 14px;
}
.login-submit-btn .logo-spin {
  width: 30px;  /* Adjust size as needed */
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}