.customer-service-container {
    margin-top: 6vh;
    background-color: #fff;
    font-family: 'Apple SD Gothic Neo', sans-serif;
  }
  
  .customer-service-title {
    left: 50%;
    margin-bottom: 8px;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    color: black;
  }
  
  .customer-service-subtitle {
    margin-bottom: 20px;
    font-size: 0.95rem;
    color: #555;
    text-align: center;
  }
  
  .customer-service-form {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 3px;
  }
  
  /* 레이블 */
  .customer-service-label {
    margin-bottom: 6px;
    font-weight: 600;
    color: #333;
  }
  
  /* 셀렉트 박스 */
  .customer-service-select {
    margin-bottom: 16px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
  
  /* 인풋 */
  .customer-service-input {
    margin-bottom: 16px;
    padding: 10px;
    font-size: 0.95rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
  
  /* 텍스트에어리어 */
  .customer-service-textarea {
    width: 100%;
    height: 25vh;
    margin-bottom: 16px;
    font-size: 0.95rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    resize: none;
  }
  
  /* 동의 체크박스 */
  .customer-service-agreement {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .customer-service-agreement-label {
    margin-left: 6px;
    font-size: 0.9rem;
    color: #333;
  }
  
  /* 전송 버튼 */
  .customer-service-submit-btn {
    margin-left: 40%;
    padding: 12px;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    background-color: seagreen; /* 원하는 색상 */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
  }
  
  /* 비활성화 상태 */
  .customer-service-submit-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* 상태 메시지 */
  .customer-service-status {
    margin-top: 12px;
    text-align: center;
    font-weight: bold;
    color: #007700;
  }
  