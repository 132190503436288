/* ===========================
   1) Main Club Detail Popup
   =========================== */

/* 오버레이 */
.club-detail-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure this is above the rest of your app */
}

/* 모달 컨테이너 */
.club-detail-popup-container {
  background: black;
  color: white;
  border-radius: 12px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  position: relative;
  box-sizing: border-box;
}

/* 애니메이션 */
.slide-in {
  animation: slideIn 300ms forwards;
}

.slide-out {
  animation: slideOut 300ms forwards;
}

@keyframes slideIn {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100vh);
  }
}

/* 뒤로 가기 버튼: 모바일에서도 잘 보이도록 절대 위치 지정 */
.popup-back-symbol-club {
  position: absolute;
  top: -8px;
  left: 48%;
  font-size: 2.1rem;
  color: white !important;
  cursor: pointer;
  z-index: 1100;
}

/* 헤더 및 타이틀 */
.club-detail-header {
  position: relative; /* Needed for absolute positioning of Instagram button */
  text-align: center;
  margin-bottom: 20px;
}

.club-detail-title {
  text-align: left;
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  margin-top: 40px; /* Avoid overlap with back button */
}

/* Instagram 버튼 스타일 */
.club-detail-instagram-button {
  position: absolute;
  top: 6.3vh;
  right: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: #e1306c; /* Instagram-like color */
  font-size: 1.7rem;
}

.club-detail-instagram-button:hover {
  opacity: 0.8;
}

/* 정보 섹션 */
.info-box {
  background-color: #2c2c2c;
  padding: 15px;
  border-radius: 8px;
  color: #ffffff;
}

.club-detail-address,
.club-detail-time,
.club-detail-nowplaying,
.club-detail-match {
  font-size: 1rem;
  margin: 10px 0;
  font-weight: bold;
}

/* 소제목 */
.section-title {
  text-align: left;
  margin-bottom: 10px;
  color: white;
}

/* 장르 섹션 */
.genre-box-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* 장르 박스 간 간격 */
}

.genre-box {
  display: inline-block;
  background: lightseagreen;
  color: white;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 8px;
  margin: 10px 0;
}

.genre-text {
  font-size: 1rem;
}

/* 이미지 섹션 */
.club-detail-images-multi {
  margin-top: 20px;
  overflow-x: scroll;
  display: flex;
  flex-wrap: nowrap;
  gap: 15px;
}

.multi-image-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.image-wrapper {
  flex: 0 0 auto;
  width: calc(100% / 2.5);
  height: 200px;
  border-radius: 8px;
  overflow: hidden;
  padding: 5px;
}

.multi-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.multi-image-container::-webkit-scrollbar {
  display: none;
}

.image-section {
  margin-top: 20px;
}

/* 미디어 쿼리: 모바일 환경 (최대 480px) */
@media (max-width: 480px) {
  .club-detail-popup-container {
    width: 100%;
    padding: 15px;
  }

  .club-detail-title {
    font-size: 2rem;
  }

  .popup-back-symbol {
    font-size: 1.3rem;
    top: 8px;
    left: 8px;
  }

  .club-detail-address,
  .club-detail-time,
  .club-detail-nowplaying,
  .club-detail-match {
    font-size: 0.9rem;
  }

  .section-title {
    font-size: 1.1rem;
  }

  .genre-text {
    font-size: 0.9rem;
  }

  .image-wrapper {
    width: 40%; /* Smaller images on mobile */
    height: 150px;
  }
}

/* ============================
   2) Enlarged Image Popup
   ============================ */

/* 오버레이(두 번째 팝업) */
.enlarged-image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* A translucent background to darken everything underneath */
  background-color: rgba(0, 0, 0, 0.6);
  /* Make sure this is higher than .club-detail-popup-overlay */
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 확대된 이미지 모달 컨텐트 */
.enlarged-image-modal-content {
  background: #121212;
  position: relative;
  max-width: 90%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 8px;
}

/* 닫기 버튼 */
.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
}

/* 확대된 이미지 */
.enlarged-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

/* Toast 알림 (예시) */
