.custom-overlay {
  position: relative;
  background-color: rgba(0, 0, 0, 0.8); /* 어두운 반투명 배경 */
  color: white;
  padding: 10px;
  border-radius: 8px;
  width: 250px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-overlay .info {
  text-align: center;
}

.custom-overlay h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.custom-overlay p {
  margin: 5px 0;
  font-size: 14px;
}

.custom-overlay .close-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #121111;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.custom-overlay .close-btn:hover {
  background-color: #ff6666;
}

.custom-marker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px; /* 마커 컨테이너 크기 */
  height: 30px; /* 마커 컨테이너 크기 */
  transform: translate(-50%, -100%);
  cursor: pointer;
}

.custom-marker .fire-icon {
  width: 24px; /* 이미지 크기 */
  height: auto; /* 비율 유지 */
}

.marker-label {
  margin-top: 1px;
  font-size: 10px;
  color: white;
  background-color: black;
  padding: 2px 5px;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
}
