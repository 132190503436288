.likes-page {
  background-color: #000;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 60px;
  box-sizing: border-box;
  overflow-y: auto; /* ✅ 스크롤 문제 해결 */
}

.likes-tabs {
  position: fixed;
  top: 6%;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  max-width: 400px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  padding: 4px;
  z-index: 1000;
}

.likes-tab {
  flex: 1;
  text-align: center;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 16px;
  background: transparent;
  color: white; /* ✅ 가독성 향상 */
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
}

.likes-tab.active {
  background-color: #2c2c2c;
  color: white;
  border: 2px solid white;
}

.likes-club-list {
  margin-top: 60px;
  padding-bottom: 80px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 100%;
}

.no-clubs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.no-clubs-button {
  padding: 10px 20px;
  background-color: #1c1c1c;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.no-clubs-button:hover {
  background-color: #333;
}

/* ✅ 보유한 입장권 UI 개선 */
.no-tickets {
  margin-top: 35%;
}
.ticket-list {
  margin-top: 80px;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 100px; /* ✅ 스크롤 문제 해결 */
}

.ticket-card {
  background: #2e2e2e;
  border-radius: 12px;
  padding: 20px; /* ✅ 패딩 증가 */
  display: flex;
  flex-direction: column; /* ✅ 수직 정렬 */
  align-items: flex-start;
  position: relative;
  width: 90%; /* ✅ 박스를 더 넓게 */
  max-width: 500px;
}

.ticket-image {
  width: 80px; /* ✅ 이미지 크기 증가 */
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
  margin-bottom: 10px;
}

.ticket-info {
  flex: 1;
  width: 100%;
}

.ticket-info h3 {
  font-size: 18px; /* ✅ 폰트 크기 증가 */
  margin-bottom: 5px;
  color: white; /* ✅ 흰색으로 변경 */
}

.ticket-info p {
  font-size: 16px; /* ✅ 폰트 크기 증가 */
  color: white; /* ✅ 가독성 개선 */
}

.ticket-quantity {
  display: flex;
  align-items: center;
  gap: 15px; /* ✅ 간격 증가 */
  margin-top: 8px;
}

.quantity-btn {
  background: #444;
  color: white;
  border: none;
  border-radius: 50%;
  width: 35px; /* ✅ 크기 증가 */
  height: 35px;
  font-size: 20px; /* ✅ 크기 증가 */
  cursor: pointer;
}

.quantity-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.use-ticket-btn {
  background: #ff4d4d;
  color: white;
  border: none;
  padding: 12px 18px; /* ✅ 버튼 크기 증가 */
  font-size: 16px; /* ✅ 폰트 크기 증가 */
  border-radius: 10px;
  cursor: pointer;
  align-self: flex-end; /* ✅ 버튼 오른쪽 정렬 */
  margin-top: 10px;
}

.refund-ticket-btn {
  background: black;
  color: white;
  border: none;
  padding: 12px 18px; /* ✅ 버튼 크기 증가 */
  font-size: 16px; /* ✅ 폰트 크기 증가 */
  border-radius: 10px;
  cursor: pointer;
  align-self: flex-end; /* ✅ 버튼 오른쪽 정렬 */
  margin-top: 10px;
}

.ticket-actions {
  display: flex;
  gap: 10px;
}

/* 기존 페이지, 탭, 티켓 카드 등은 그대로 유지 */

/* 팝업 컨테이너: 조금 더 작게, 아래 흰색 부분 보이게 */
.ticket-popup {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 400px;
  background: #fff;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.3);
  transition: bottom 0.4s ease-in-out;
  z-index: 2000;
  bottom: -100%;
  height: 60%; /* 전체 팝업 높이를 60%로 줄임 */
  overflow: hidden; 
}

.ticket-popup.active {
  bottom: 15%; /* 조금 더 위로 올려서 흰색 부분이 아래에 보이게 */
}

/* 팝업 전체 레이아웃 */
.ticket-content {
  width: 100%;
  height: 100%;
  position: relative;
}

/* 상단 핸들(끌어내리는 표시) */
.ticket-handle {
  width: 50px;
  height: 5px;
  background: #ccc;
  border-radius: 2px;
  margin: 8px auto;
}

/* 검정 티켓 박스: 높이 자동, 아래쪽 여백 추가 */
.ticket-inner {
  position: relative;
  background: #1c1c1c;
  border-radius: 20px;
  margin: 10px 20px; /* 좌우, 상단 10px, 하단 20px */
  padding: 20px;
  color: #fff;
  /* 높이 자동으로 (내용 많지 않으면 더 짧아짐) */
  height: auto;
  /* 남는 공간이 있어도 아래쪽에 여백이 생기도록 */
  max-height: calc(100% - 50px); 
  overflow-y: auto;
}

/* 양옆을 반원으로 파낸 티켓 모양 */
.ticket-inner::before,
.ticket-inner::after {
  content: "";
  position: absolute;
  width: 28px; 
  height: 28px;
  background: #fff; 
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.ticket-inner::before {
  left: -14px;
}

.ticket-inner::after {
  right: -14px;
}

/* 티켓 상단 제목 */
.ticket-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
  text-align: center;
}

/* "입장 가능 시간" 라벨 */
.ticket-entrance-label {
  font-size: 14px;
  margin-bottom: 5px;
  color: #ccc; 
  text-align: center;
}

/* 시간 표시 */
.time-label {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

/* 구분선 */
.ticket-separator {
  border: none;
  height: 1px;
  background-color: #444;
  margin: 10px 0 15px;
  width: 100%;
}

/* 안내 문구 */
.ticket-instruction-large {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

/* 닫기 버튼 - 아래쪽 여백 */
.close-ticket-btn {
  margin-top: 10px;
  margin-bottom: 10px; /* 여백 */
  padding: 10px 20px;
  font-size: 16px;
  background: #ff4d4d;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
