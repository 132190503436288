/* EditInfo.css */

.edit-info-page {
  background-color: #000;
  color: #fff;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

/* Heading row: back button + title on same line */
.heading-row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 500px;
  margin-top: 5vh;
  margin-bottom: 1vh;
}

.heading-row h2 {
  margin: 0;
  margin-left: 20px;
  color: #1db954;
}

.back-button {
  background: none;
  color: #1db954;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.3s;
}

.back-button:hover {
  color: #a1e15c;
}

.info-form {
  width: 100%;
  max-width: 500px;
  background-color: #111;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.edit-section,
.account-section,
.marketing-section {
  margin-bottom: 20px;
}

h3 {
  margin-bottom: 20px;
  color: #1db954;
}

.input-group {
  margin-top: 5px;
}

.input-group label {
  display: block;
  margin-bottom: 8px;
}

.input-field {
  width: calc(100% - 60px);
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  outline: none;
}

/* phone-row: line 1 => phone + 인증 button */
.phone-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.phone-field {
  max-width: 180px;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  outline: none;
}

/* verify-row: line 2 => code input + 확인 button */
.verify-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px; /* some spacing below */
}

.code-field {
  max-width: 160px;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  outline: none;
}

.verify-btn {
  padding: 10px 14px;
  background-color: #444;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.verify-btn:hover {
  background-color: #555;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox-container input {
  margin-right: 10px;
}

.save-btn {
  width: 100%;
  padding: 15px;
  background-color: #1db954;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 10px;
}

.save-btn:hover {
  background-color: #17a74a;
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.delete-account-btn {
  width: 100%;
  padding: 15px;
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.delete-account-btn:hover {
  background-color: #e63c3c;
}
.marketing-section{
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
}
/* 모바일 반응형 */
@media (max-width: 480px) {
  .edit-info-page {
    padding: 10px;
  }
  .info-form {
    padding: 15px;
    width: 90%;
  }
  .heading-row h2 {
    font-size: 1.2rem;
    margin-left: 10px;
  }
  .marketing-section .h3 {
    font-size: 1rem;
    margin-bottom: 1.2vh;

  }
  .phone-field {
    max-width: 120px;
  }
  .code-field {
    max-width: 120px;
  }
  .verify-btn {
    font-size: 0.9rem;
    padding: 8px;
  }
  .save-btn, 
  .delete-account-btn {
    font-size: 0.9rem;
    padding: 12px;
  }
}
