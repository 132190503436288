.marketing-consent-page {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 50px; /* 마지막 내용이 잘리지 않도록 여백 추가 */
    line-height: 1.6;
    font-family: 'Inter', sans-serif;
    color: #fff;
    background-color: #000;
    overflow-y: auto;
    z-index: 9999;
    /* 초기 상태: 아래로 완전히 숨겨짐 */
    transform: translateY(100%);
    animation: slideUp 0.5s ease-out forwards;
  }
  
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  .marketing-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2rem;
    color: #fff;
  }
  
  .marketing-section {
    margin-bottom: 30px;
  }
  
  .marketing-section h2 {
    font-size: 1.4rem;
    margin-bottom: 10px;
    color: #fff;
  }
  
  .marketing-section p {
    font-size: 1rem;
    white-space: pre-line;
  }
  
  .close-button {
    position: absolute;
    top: 40px;
    right: 20px;
    background: transparent;
    border: none;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    z-index: 10000;
  }
  
  .close-button:hover {
    color: #aaa;
  }
  
  