
  .back-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    align-self: flex-start;
    margin-bottom: 20px;
  }
  

  
  h2 {
    font-size: 24px;
    color: var(--point-color);
    margin-bottom: 20px;
  }
  

  
  h3 {
    margin: 0;
    color: var(--point-color);
  }
  

  
  label {
    font-size: 16px;
    color: #fff;
  }
  
  input[type="checkbox"] {
    transform: scale(1.2);
    cursor: pointer;
  }
  
  .save-button {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    margin-top: 20px;
    background-color: #1db954;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .save-button:hover {
    background-color: #17a74a;
  }
  