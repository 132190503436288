/* announcements.css */
/* Container for the whole announcements section */
.announcements-container {
    background: black;
    color: white;
    max-width: 100%;
    padding: 20px;
    margin-bottom: 10vh;
    min-height: 100vh;
  }
  
  /* Section title */
  .announcements-title {
    color: white;
    margin-bottom: 1rem;
    font-size: 1.8rem;
    border-bottom: 2px solid #ccc;
    padding-bottom: 0.5rem;
  }
  
  /* Individual announcement item wrapper */
  .announcement-item {
    border: 3px solid white;
    border-radius: 20px;
    padding: 16px;
    margin-bottom: 16px;
  }
  
  /* Title inside each announcement */
  .announcement-title {
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  /* Content inside each announcement */
  .announcement-content {
    margin-bottom: 0.5rem;
  }
  
/* Date styling */
.announcement-date {
  color: white;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

/* Pinned badge */
  .pinned-badge {
    background-color: #ffeb3b;
    color: #333;
    font-weight: bold;
    display: inline-block;
    padding: 2px 6px;
    margin-left: 8px;
    border-radius: 4px;
  }
  
  /* Loading or error messages */
  .loading-text,
  .error-text {
    margin: 16px 0;
    font-style: italic;
  }
  