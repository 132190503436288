.search-clubs-page {
  background-color: black; 
  color: #ffffff; 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 70px;
  font-family: 'Inter', sans-serif;
  position: relative;
  overflow: hidden; /* The main page won’t scroll; only the .club-cards-container and dropdowns do */
}

.search-header {
  position: fixed;
  width: 30vh;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 1000;
}

.search-header h2 {
  font-size: 26px;
  color: #000000; 
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 15px;
}

.search-bar {
  position: relative;
  top: 7vh;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 1000;
  width: 85%;
  max-width: 500px;
}

.search-bar input {
  width: 100%;
  padding: 15px 20px 15px 45px;
  border: none;
  border-radius: 30px;
  background-color: var(--primary-color);
  color: #ffffff;
  font-size: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  outline: none;
}

.search-icon {
  position: absolute;
  left: 3vw;        
  top: 52%;
  transform: translateY(-50%);
}

.search-bar input::placeholder {
  color: #666;
  font-weight: 500;
}

/* 필터링용 */
.filters-row {
  position: fixed;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  display: flex;
  z-index: 1000;
  align-items: center;
}

.area-dropdown {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  top: 11vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.area-dropdown .dropdown-btn {
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 8px 8px;
  border-radius: 20px;
  border: 0.8px solid #333;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 12vh;
  text-align: center;
  text-transform: uppercase;
}

.area-dropdown .dropdown-btn:hover {
  background-color: #2e2e2e;
}

.area-dropdown .dropdown-btn.active {
  color: #2e2e2e;
  background-color: var(--point-color);
  border-color: var(--point-color);
}

/* 드롭다운 메뉴1 */
.area-dropdown .dropdown-menu {
  display: none;
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--primary-color);
  border: 1px solid #333;
  border-radius: 25px;
  padding: 1vw;
  width: 30%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);

  /* NEW: Add a max-height + overflow to allow scrolling in this dropdown */
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.area-dropdown .dropdown-menu.show {
  display: block;
}

.area-dropdown .dropdown-menu button {
  background-color: transparent;
  color: #ffffff;
  width: 100%;
  text-align: center;
  padding: 12px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
}

.area-dropdown .dropdown-menu button:hover {
  background-color: #2e2e2e;
}

.area-dropdown .dropdown-menu button:last-child {
  border-bottom: none;
}

.area-dropdown .dropdown-menu button.active {
  background-color: var(--point-color);
  color: #000;
}

/* 드롭다운 메뉴2 */
.area-dropdown .dropdown-menu2 {
  display: none;
  position: absolute;
  top: 35px;
  left: 85%;
  transform: translateX(-50%);
  background-color: var(--primary-color);
  border: 1px solid #333;
  border-radius: 25px;
  padding: 1vw;
  width: 30%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);

  /* NEW: Add a max-height + overflow to allow scrolling in this dropdown */
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.area-dropdown .dropdown-menu2.show {
  display: block;
}

.area-dropdown .dropdown-menu2 button {
  background-color: transparent;
  color: #ffffff;
  width: 100%;
  text-align: center;
  padding: 12px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
}

.area-dropdown .dropdown-menu2 button:hover {
  background-color: #2e2e2e;
}

.area-dropdown .dropdown-menu2 button:last-child {
  border-bottom: none;
}

.area-dropdown .dropdown-menu2 button.active {
  background-color: var(--point-color);
  color: #000;
}

.filter-options {
  position: absolute;
  top: 180px;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  gap: 20px;
  max-width: 300px;
  z-index: 1000;
}

.filter-options .filter-btn {
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 20px;
  border: 1px solid #333;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-options .filter-btn:hover {
  background-color: #2e2e2e;
}

.filter-options .filter-btn.active {
  background-color: var(--point-color);
  color: #000;
}

.map-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 55vh; /* Adjusted to make room for Navbar */
  z-index: 1;
}

/* 
   .club-list는 드래그로 높이가 바뀌지만,
   내부 스크롤은 막고, overflow: hidden으로 설정
*/
.club-list {
  position: fixed;
  bottom: 75px;
  left: 0;
  width: 100%;
  min-height: 30vh;
  max-height: 71vh;
  height: 40vh; /* 기본 높이 */
  background-color: black;
  transition: height 0.4s ease;
  z-index: 1000;
  overflow: hidden; /* 내부 스크롤을 막음 */
}

/* 드래그 핸들은 상단에 절대배치 */
.drag-handle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  z-index: 1001;
  background-color: transparent; /* 원한다면 rgba로 처리 가능 */
}

.drag-handle-bar {
  width: 50px;
  height: 4px;
  background-color: #ccc;
  border-radius: 5px;
  margin: 3px 0;
}

.club-cards-container {
  position: absolute;
  top: 30px;  /* drag-handle 높이만큼 띄움 */
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 10px 20px;

  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: flex-start;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.popup-container {
  background: rgba(30, 30, 30, 0.9);
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
}

.popup-club-image {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px;
}

.popup-container h3 {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  color: var(--point-color);
}

.popup-container p {
  font-size: 16px;
  color: #aaa;
  margin-bottom: 10px;
}

.close-popup-btn {
  background-color: #66ffa6;
  color: #000;
  padding: 12px 25px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 25px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.close-popup-btn:hover {
  background-color: #4fd889;
  transform: translateY(-3px);
}

.popup-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin: 25px 0;
}

.genre-btn {
  padding: 15px;
  background-color: #333;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: white;
  transition: background-color 0.2s ease;
}

.genre-btn:hover {
  background-color: #444;
}

.genre-btn.active {
  background-color: var(--point-color);
  color: #000;
}

@media (max-width: 480px) {
  .search-header h2 {
    font-size: 20px;
  }
  .search-bar input {
    padding: 9px 9px;
  }
  .area-buttons,
  .filter-options {
    flex-direction: column;
    gap: 10px;
  }
}

.club-card {
  flex: 1 1 calc(50% - 20px);
  max-width: calc(50% - 20px);
  background-color: var(--primary-color);
  color: white;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  transition: transform 0.3s ease;
}

.loading-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Adjust based on your design */
}

.logo-spin {
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
