:root {
  --background-color: #0a0a0a;
  --text-color: #ffffff;
  --text-secondary: #b3b3b3;
  --accent-color: #1db954; /* 초록색 */
  --button-hover-color: #c3f96e;
}

/* ✅ 전체 페이지 스타일 */
.find-pw-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 20px;
  font-family: 'Inter', sans-serif;
}

/* ✅ 제목 스타일 */
.find-pw-title {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center;
}

/* ✅ 설명 문구 */
.find-pw-description {
  font-size: 1rem;
  margin-bottom: 30px;
  text-align: center;
  max-width: 300px;
  color: var(--text-secondary);
}

/* ✅ 입력 폼 스타일 */
.find-pw-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

/* ✅ 입력 필드 스타일 */
.input-field {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  background-color: #222;
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  font-size: 1rem;
}

/* ✅ 버튼 스타일 */
.verification-btn,
.find-pw-submit-btn {
  width: 88%;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s;
}

/* ✅ 인증번호 요청 버튼 */
.verification-btn {
  background-color: #666;
  color: var(--text-color);
  margin-bottom: 15px;
}

.verification-btn:hover {
  background-color: #888;
}

/* ✅ 제출 버튼 (비밀번호 찾기) */
.find-pw-submit-btn {
  background-color: var(--accent-color);
  color: var(--background-color);
}

.find-pw-submit-btn:hover {
  background-color: var(--button-hover-color);
}
